import React from "react"
import Slider from "@material-ui/core/Slider"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles({
  sliderColor: {
    color: "#00745F",
    width: "100px",
  },
  controlsContainer: {
    position: "absolute",
    bottom: "10px",
    right: "10px",
    zIndex: 10,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    padding: "10px",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
  },
  fullscreenButton: {
    color: "#fff",
    marginLeft: "10px",
    cursor: "pointer",
  },
  muteButton: {
    color: "#fff",
    marginLeft: "10px",
    cursor: "pointer",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    padding: "5px 10px",
    borderRadius: "5px",
  },
  videoContainer: {
    width: "100%",
    height: "520px",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "black",
  },
  videoElement: {
    width: "100% !important",
    height: "auto !important",
    objectFit: "contain !important",
    position: "relative !important"
  },
})

const AgoraVideoContainer = ({ isAddVideoId, volumen, vol, audioTrack }) => {
  const [value, setValue] = React.useState(vol)
  const classes = useStyles()

  React.useEffect(() => {
    const videoElement = document.querySelector(`[id^='video_track-video-${isAddVideoId}']`)
    const playerElement = document.querySelector(`[id^='agora-video-player-track-video-${isAddVideoId}']`)

    if (playerElement) {
      playerElement.className = classes.videoContainer
    }

    if (videoElement) {
      videoElement.className = classes.videoElement
      videoElement.muted = true // Inicialmente silenciado
      videoElement.play().catch(error => {
        console.log("Error al reproducir el video:", error)
      })
    }

    if (audioTrack) {
      audioTrack.setVolume(value); // Ajusta el volumen del audio
      console.log(value)
    }
  }, [isAddVideoId, classes.videoElement])

  const handleChange = (event, newValue) => {
    setValue(newValue);
    volumen(newValue);

    if (audioTrack) {
      console.log(newValue)

      audioTrack.setVolume(newValue); // Cambiar el volumen del audio
    }
  };



  const toggleFullscreen = () => {
    const videoElement = document.getElementById(isAddVideoId)
    if (videoElement) {
      if (!document.fullscreenElement) {
        if (videoElement.requestFullscreen) {
          videoElement.requestFullscreen()
        } else if (videoElement.mozRequestFullScreen) {
          videoElement.mozRequestFullScreen() // Firefox
        } else if (videoElement.webkitRequestFullscreen) {
          videoElement.webkitRequestFullscreen() // Chrome, Safari y Opera
        } else if (videoElement.msRequestFullscreen) {
          videoElement.msRequestFullscreen() // IE/Edge
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen() // Firefox
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen() // Chrome, Safari y Opera
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen() // IE/Edge
        }
      }
    }
  }

  return (
    <div className={classes.videoContainer} id={isAddVideoId}>
      <div className={classes.controlsContainer}>
        <Slider
          value={value}
          onChange={handleChange}
          aria-labelledby="continuous-slider"
          className={classes.sliderColor}
        />
        <span onClick={toggleFullscreen} className={classes.fullscreenButton}>
          Fullscreen
        </span>
      </div>
    </div>
  )
}

export default AgoraVideoContainer
